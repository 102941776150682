const categoryTranslation = {
	kvinder: 'women',
	mænd: 'men',
	børn: 'kids',
} as const

export function translateCategory(category: string): string {
	// Normalize the category by converting to lowercase and trimming any extra spaces
	const normalizedCategory = category.toLowerCase().trim()

	// Check if the normalized category exists in the categoryTranslation object
	if (normalizedCategory in categoryTranslation) {
		return categoryTranslation[
			normalizedCategory as keyof typeof categoryTranslation
		]
	}

	// Return the original category if it doesn't exist in the translation map
	return category
}
