import SearchTrendingContent from '../../components/storyblok/search-clerk-trending'
import PopularGridSuggestions from './components/search-popular-suggestions'

const SearchInitialState = ({ closeOverlay, handleSuggestionClick }: any) => {
	return (
		<div className="flex flex-col gap-4 overflow-y-auto px-4 py-6 shadow-primary-plenty md:grid md:grid-cols-3">
			<div className="flex flex-col md:col-span-1">
				<PopularGridSuggestions onSuggestionClick={handleSuggestionClick} />
			</div>
			<div className="overflow-hidden md:col-span-2">
				<SearchTrendingContent />
			</div>
		</div>
	)
}

export default SearchInitialState
