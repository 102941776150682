import { SlideClose } from '#app/components/navigation/mobile-navbar/slides'
import { Icon } from '#app/components/ui/icon'
import { Input } from '#app/components/ui/input'
import { Label } from '#app/components/ui/label'
import { cn } from '#app/utils/misc'

interface ClerkSearchInputV2Props
	extends React.HTMLAttributes<HTMLInputElement> {
	value: string
	placeholder: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	onFocus?: () => void
	onBlur?: () => void
	onClick?: () => void
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void // Add onKeyDown
	closeSearch?: boolean
	onCloseSearch?: () => void
}

const ClerkSearchInputV2 = ({
	id,
	className,
	value,
	placeholder,
	onChange,
	onFocus,
	onBlur,
	onClick,
	onKeyDown, // Include onKeyDown in the destructured props
	closeSearch,
	onCloseSearch,
	...rest
}: ClerkSearchInputV2Props) => {
	return (
		<div className="flex-1 rounded-3xl">
			<Label htmlFor={id} className="sr-only">
				{id || 'Search'}
			</Label>
			<div
				className={cn(
					'relative flex w-full items-center rounded-3xl md:min-w-[300px] lg:min-w-[400px]',
					className,
				)}
			>
				<SlideClose className="relative items-center">
					<Icon
						className="text-body-md absolute left-4 top-4"
						size="lg"
						name="arrow-left"
						onClick={onCloseSearch} // Call onCloseSearch when this icon is clicked
					/>
				</SlideClose>
				<Input
					type="text"
					name="terms"
					value={value}
					placeholder={placeholder}
					className={cn(
						'w-full rounded-3xl border bg-white py-4 pl-12 pr-6 ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-0',
						value
							? 'border-black text-black'
							: 'border-beige-90 text-beige-100',
						'focus:border-black focus:text-black', // Ensure black border and text on focus
					)}
					onChange={onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					onClick={onClick}
					onKeyDown={onKeyDown} // Attach the onKeyDown handler to the input
					{...rest}
				/>
			</div>
		</div>
	)
}

export default ClerkSearchInputV2
