import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
} from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import { Icon } from '#app/components/ui/icon'
import { type DiscountStoryblok } from '#types/component-types-sb'
const DiscountCowndown = ({ discount }: DiscountStoryblok) => {
	const [timeRemaining, setTimeRemaining] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
	})
	const endDate = new Date(discount.endTimeForDiscount)
	const { t } = useTranslation('navbar')

	const calculateTimeLeft = () => {
		const now = new Date()
		const days = differenceInDays(endDate, now)
		const hours = differenceInHours(endDate, now) % 24
		const minutes = differenceInMinutes(endDate, now) % 60

		return { days, hours, minutes }
	}

	useEffect(() => {
		setTimeRemaining(calculateTimeLeft())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<div className="absolute -bottom-3 left-1 flex w-fit items-center gap-1 self-center rounded-[12px] border border-black bg-white px-2 py-[2px] text-center">
			<Icon className="text-black" size="xs" name="clock" />
			<PlentyBody size="sm">
				{timeRemaining.days}D {timeRemaining.hours}
				{t('hours')} {timeRemaining.minutes}M
			</PlentyBody>
		</div>
	)
}

export default DiscountCowndown
