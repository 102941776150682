import { useTranslation } from 'react-i18next'
import { HelpLink } from '../../../footer-components/quick-help-links'

const QuickHelpLinksNoHeader = () => {
	const { t } = useTranslation('footer')

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-2">
				<HelpLink to="/faq" label={t('faq')} />
				<HelpLink
					to="/click-and-collect-and-delivery"
					label={t('shipping_&_delivery')}
				/>
				<HelpLink to="/returns-and-refunds" label={t('returns_&_refunds')} />
			</div>
		</div>
	)
}

export default QuickHelpLinksNoHeader
