import { useLocation, useNavigate } from '@remix-run/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import MobileNavFooter from '#app/components/navigation/mobile-navbar/footer/mobile-nav-footer'
import { translateCategory } from '#app/utils/search/categories/clerk-gender-danish-to-english'
import { capitalizeWords } from '#app/utils/string-utils'
import { ClerkSearchBarV2 } from './clerk-search-bar-v2'
import SearchInitialStateV2 from './clerk-search-initial-state-v2'
import SearchResultsV2 from './clerk-search-searching-state-v2'

const MobileNavClerkSearch = ({
	closeOverlay,
	closeDrawer,
	selectedCategory,
}: any) => {
	const [searchTerm, setSearchTerm] = useState('')

	const [, setIsFocused] = useState(false)
	const [hits, setHits] = useState(0)
	const [productDataLength, setProductDataLength] = useState(0)
	const location = useLocation()
	const [brands, setBrands] = useState<string[]>([])
	const [productNames, setProductNames] = useState<string[]>([])
	const [pageSearchUrl, setPageSearchUrl] = useState<any>(null)
	const prevLocation = useRef(location.pathname)
	const overlayRef = useRef<HTMLDivElement>(null)
	const navigate = useNavigate()

	const updateHits = (newHits: React.SetStateAction<number>) => {
		setHits(newHits)
	}
	const translatedCategory = selectedCategory
		? translateCategory(selectedCategory)
		: ''
	const updateProductDataLength = (length: number) => {
		setProductDataLength(length)
	}

	const handleSearchChange = (newSearchTerm: React.SetStateAction<string>) => {
		setSearchTerm(newSearchTerm)
	}

	const handleSuggestionClick = (suggestion: string) => {
		setSearchTerm(suggestion)

		// Navigate to /clerk-search with the suggestion and gender filter
		navigate(
			`/clerk-search?query=${suggestion}${
				'&category=' + capitalizeWords(translatedCategory)
			}`,
		)
		closeOverlay()
	}

	const handleFocus = () => {
		setIsFocused(true)
	}
	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (hits > 0 && event.key === 'Enter') {
			event.preventDefault() // Prevent default form submission
			navigate(
				`/clerk-search?query=${searchTerm}${
					'&category=' + capitalizeWords(translatedCategory)
				}`,
			)
			closeOverlay()
		}
	}

	const stableCloseOverlay = useCallback(closeOverlay, [closeOverlay])

	useEffect(() => {
		if (prevLocation.current !== location.pathname) {
			stableCloseOverlay()
			prevLocation.current = location.pathname
		}
	}, [location, stableCloseOverlay])

	useEffect(() => {
		const handleGlobalKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				stableCloseOverlay()
			}
		}

		const handleClickOutside = (event: MouseEvent) => {
			if (
				overlayRef.current &&
				!overlayRef.current.contains(event.target as Node)
			) {
				stableCloseOverlay()
			}
		}

		document.addEventListener('keydown', handleGlobalKeyDown)
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('keydown', handleGlobalKeyDown)
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [stableCloseOverlay])
	return (
		<div className="relative flex w-full flex-col ">
			<div
				className="flex w-full flex-col items-center justify-center px-4"
				onClick={e => e.stopPropagation()}
			>
				<ClerkSearchBarV2
					searchTerm={searchTerm}
					onSearchChange={handleSearchChange}
					shouldAutoFocus={true}
					closeSearch={true}
					closeOverlay={closeOverlay}
					onFocus={handleFocus}
					closeDrawer={closeDrawer}
					onKeyDown={handleKeyDown}
					useShadow={true}
				/>
				<div
					className={`pointer-events-auto absolute top-full mt-2 w-full rounded-[24px] transition-opacity duration-300 ease-in-out`}
				>
					{searchTerm ? (
						<div className="flex flex-col gap-8">
							<SearchResultsV2
								searchTerm={searchTerm}
								genderFilter={translatedCategory}
								productDataLength={productDataLength}
								closeOverlay={closeOverlay}
								updateHits={updateHits}
								updateProductDataLength={updateProductDataLength}
								setBrands={setBrands}
								setProductNames={setProductNames}
								handleSuggestionClick={handleSuggestionClick}
								brands={brands}
								productNames={productNames}
								updatePageSearchUrl={setPageSearchUrl}
								pageSearchUrl={pageSearchUrl}
							/>
							<MobileNavFooter smallVersion />
						</div>
					) : (
						<div className="flex flex-col gap-8">
							<SearchInitialStateV2
								handleSuggestionClick={handleSuggestionClick}
							/>
							<MobileNavFooter smallVersion />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default MobileNavClerkSearch
