import SeparatorLine from '#app/components/common/separator-line'
import SearchTrendingContent from '../../components/storyblok/search-clerk-trending'
import PopularGridSuggestions from '../clerk-search/components/search-popular-suggestions'

const SearchInitialStateV2 = ({ handleSuggestionClick }: any) => {
	return (
		<div className="flex flex-col gap-3 rounded-b-2xl px-4 pb-3 pt-2 md:grid md:grid-cols-3">
			<div className="flex flex-col md:col-span-1">
				<PopularGridSuggestions onSuggestionClick={handleSuggestionClick} />
			</div>
			<SeparatorLine />
			<div className="md:col-span-2">
				<SearchTrendingContent />
			</div>
		</div>
	)
}

export default SearchInitialStateV2
