import {
	type DiscountStoryblok,
	type NavigationCategoriesStoryblok,
} from '#types/component-types-sb'
import DiscountTrigger from './discount-trigger'
import MobileNavStaticInfo from './mobile-nav-static-info'
import TopNavigation from './top-navigation'

interface MobileNavLayoutProps {
	selectedCategory: string | null
	onCategoryClick: (title: string) => void
	navigationData: NavigationCategoriesStoryblok[]
	discounts: DiscountStoryblok[]
}

const MobileNavLayout = ({
	selectedCategory,
	onCategoryClick,
	navigationData,
	discounts,
}: MobileNavLayoutProps) => {
	return (
		<div className="space-y-3 px-4">
			<TopNavigation
				selectedCategory={selectedCategory}
				onCategoryClick={onCategoryClick}
				navigationData={navigationData}
			/>
			<DiscountTrigger
				selectedCategory={selectedCategory}
				onCategoryClick={onCategoryClick}
				navigationData={navigationData}
				discounts={discounts}
			/>
			<MobileNavStaticInfo />
		</div>
	)
}

export default MobileNavLayout
