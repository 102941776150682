import { useLocation, useNavigate } from '@remix-run/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ClerkSearchBar } from '#app/features/clerk-search/clerk-search-bar'
import { capitalizeWords } from '#app/utils/string-utils'
import SearchInitialState from './search-initial-state'
import SearchResults from './search-searching-state'

const getInitialGenderFilter = (lastVisitedLanding = '') => {
	if (lastVisitedLanding.includes('women')) {
		return 'women'
	} else if (lastVisitedLanding.includes('men')) {
		return 'men'
	} else if (lastVisitedLanding.includes('kids')) {
		return 'kids'
	} else {
		return 'all'
	}
}

const SearchOverlay = ({
	closeOverlay,
	closeDrawer,
	lastVisitedLanding,
}: any) => {
	const [searchTerm, setSearchTerm] = useState('')
	const [genderFilter, setGenderFilter] = useState(
		getInitialGenderFilter(lastVisitedLanding),
	)
	const [isFocused, setIsFocused] = useState(false)
	const [hits, setHits] = useState(0)
	const [productDataLength, setProductDataLength] = useState(0)
	const location = useLocation()
	const [brands, setBrands] = useState<string[]>([])
	const [productNames, setProductNames] = useState<string[]>([])
	const [pageSearchUrl, setPageSearchUrl] = useState<any>(null)
	const prevLocation = useRef(location.pathname)
	const overlayRef = useRef<HTMLDivElement>(null)
	const navigate = useNavigate()

	const updateHits = (newHits: React.SetStateAction<number>) => {
		setHits(newHits)
		if (newHits === 0 && searchTerm.length > 1) {
			setGenderFilter('all')
		}
	}

	const updateProductDataLength = (length: number) => {
		setProductDataLength(length)
	}

	const handleSearchChange = (newSearchTerm: React.SetStateAction<string>) => {
		setSearchTerm(newSearchTerm)
	}

	const handleGenderFilterChange = (newFilter: string) => {
		setGenderFilter(newFilter)
	}

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (event.target === overlayRef.current) {
			closeOverlay()
		}
	}

	const handleSuggestionClick = (suggestion: string) => {
		setSearchTerm(suggestion)
		navigate(
			`/clerk-search?query=${suggestion}${
				genderFilter === 'all'
					? ''
					: '&category=' + capitalizeWords(genderFilter)
			}`,
		)
		closeOverlay()
	}

	const handleFocus = () => {
		setIsFocused(true)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (hits > 0 && event.key === 'Enter') {
			navigate(
				`/clerk-search?query=${searchTerm}${
					genderFilter === 'all'
						? ''
						: '&category=' + capitalizeWords(genderFilter)
				}`,
			)
			closeOverlay()
		}
	}

	const stableCloseOverlay = useCallback(closeOverlay, [closeOverlay])

	useEffect(() => {
		if (prevLocation.current !== location.pathname) {
			stableCloseOverlay()
			prevLocation.current = location.pathname
		}
	}, [location, stableCloseOverlay])

	useEffect(() => {
		const handleGlobalKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				stableCloseOverlay()
			}
		}

		const handleClickOutside = (event: MouseEvent) => {
			if (
				overlayRef.current &&
				!overlayRef.current.contains(event.target as Node)
			) {
				stableCloseOverlay()
			}
		}

		document.addEventListener('keydown', handleGlobalKeyDown)
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('keydown', handleGlobalKeyDown)
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [stableCloseOverlay])


	return (
		<div
			ref={overlayRef}
			onClick={handleOverlayClick}
			className="relative flex w-full flex-col bg-white"
		>
			<div
				className="flex w-full flex-col items-center justify-center px-4 pt-4 md:px-0 md:pt-0"
				onClick={e => e.stopPropagation()}
			>
				<ClerkSearchBar
					searchTerm={searchTerm}
					onSearchChange={handleSearchChange}
					shouldAutoFocus={true}
					closeSearch={true}
					closeOverlay={closeOverlay}
					onFocus={handleFocus}
					closeDrawer={closeDrawer}
					onKeyDown={handleKeyDown}
					useShadow={true}
				/>
				<div
					className={`absolute top-full z-50 mt-2 w-full rounded-[24px] bg-white ${
						isFocused ? 'opacity-100' : 'opacity-0'
					} pointer-events-auto transition-opacity duration-300 ease-in-out`}
				>
					{searchTerm ? (
						<SearchResults
							searchTerm={searchTerm}
							genderFilter={genderFilter}
							productDataLength={productDataLength}
							closeOverlay={closeOverlay}
							updateHits={updateHits}
							updateProductDataLength={updateProductDataLength}
							handleGenderFilterChange={handleGenderFilterChange}
							setBrands={setBrands}
							setProductNames={setProductNames}
							handleSuggestionClick={handleSuggestionClick}
							brands={brands}
							productNames={productNames}
							updatePageSearchUrl={setPageSearchUrl} // Pass the function to SearchResults
							pageSearchUrl={pageSearchUrl} // Pass the actual data as well
						/>
					) : (
						<SearchInitialState
							closeOverlay={closeOverlay}
							handleSuggestionClick={handleSuggestionClick}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export default SearchOverlay
