import { useNavigation } from '@remix-run/react'
import { useEffect, useState } from 'react'
import { cn } from '#app/utils/misc'
import {
	type CategoryStoryblok,
	type ParentCategoryStoryblok,
} from '#types/component-types-sb'
import CategoryGrid from './category-grid'

const SubMenu = ({
	content,
	brands,
}: {
	content: CategoryStoryblok | ParentCategoryStoryblok | undefined
	brands: (CategoryStoryblok | ParentCategoryStoryblok) | undefined
}) => {
	const location = useNavigation().location
	// Hide the submenu on URL change
	const [hideOnURLChange, setHideOnURLChange] = useState(false)
	// TODO: Find a better way to handle this close action
	useEffect(() => {
		setHideOnURLChange(true)
		const interval = setInterval(() => {
			setHideOnURLChange(false)
		}, 200)

		return () => {
			clearTimeout(interval)
			setHideOnURLChange(false)
		}
	}, [location?.pathname])

	return (
		<div
			className={cn(
				'invisible absolute right-0 z-20 w-full border-t-2 border-t-beige-70 bg-white opacity-0 shadow-sm transition-all delay-75 hover:visible hover:opacity-100 peer-hover:visible peer-hover:opacity-100',
				hideOnURLChange && 'invisible hidden opacity-0',
			)}
		>
			{content && (
				<div className="px-14 py-2">
					<CategoryGrid content={content} brands={brands} />
				</div>
			)}
		</div>
	)
}

export default SubMenu
