import { AnimatePresence, motion } from 'framer-motion'
import { cn } from '#app/utils/misc'

interface ScrollableContentProps {
	children: React.ReactNode
	className?: string
}

const ScrollableContent = ({ children, className }: ScrollableContentProps) => {
	return (
		<AnimatePresence>
			<motion.div
				className={cn('flex flex-col', className)}
				data-radix-scroll-area-viewport
				style={{ overflowY: 'scroll' }}
				initial={{ y: 10, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				exit={{ y: -10, opacity: 0 }}
				transition={{ duration: 0.2 }}
			>
				{children}
			</motion.div>
		</AnimatePresence>
	)
}

export default ScrollableContent
