import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import { SheetClose } from '#app/components/ui/sheet'
import { cn } from '#app/utils/misc'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'

interface SearchNavigationProps {
	selectedCategory: string | null
	onCategoryClick: (title: string) => void
	navigationData: NavigationCategoriesStoryblok[]
}
const SearchNavigation = ({
	selectedCategory,
	navigationData,
	onCategoryClick,
}: SearchNavigationProps) => {
	const { t } = useTranslation('navbar')
	
	return (
		<div className="space-y-3 bg-white px-4">
			<div className="flex flex-row items-center justify-between pb-1 pt-4">
				<PlentyBody>{t('you_are_searching_in')}</PlentyBody>
				<div className="flex flex-row space-x-4">
					{navigationData.map(item => {
						const isActive = selectedCategory === item.title
						return (
							<div
								className={cn(
									'relative flex w-full cursor-pointer items-center justify-center',
								)}
								onClick={() => onCategoryClick(item.title!)}
								key={item._uid}
							>
								<PlentyButtonsAndLinks
									as="nav-md"
									className={cn(' text-black-90', isActive && 'font-bold')}
								>
									{item.title}
								</PlentyButtonsAndLinks>
								{isActive && (
									<div
										className="absolute bottom-0 h-[2px] w-6 rounded-full bg-black-90"
										id="underline-search"
									/>
								)}
							</div>
						)
					})}
				</div>
				{/* Ensure this close button only closes the sheet when intentionally clicked */}
				<SheetClose className="flex items-center justify-end">
					<Icon name="close" size="lg" />
				</SheetClose>
			</div>
		</div>
	)
}

export default SearchNavigation
