import SearchGenderFilter from './components/search-gender-filter'
import PredictiveSearchGrid from './components/search-predictive-grid-items'
import GridSuggestions from './components/search-suggestions'

const SearchResults = ({
	searchTerm,
	genderFilter,
	productDataLength,
	closeOverlay,
	updateHits,
	updateProductDataLength,
	handleGenderFilterChange,
	setBrands,
	setProductNames,
	handleSuggestionClick,
	brands,
	productNames,
	updatePageSearchUrl,
	pageSearchUrl, // Receive the actual data as well
}: any) => {

	const EIGHT_DIGIT_REGEX = /^\d{8}$/

	return (
		<>
			{searchTerm.match(EIGHT_DIGIT_REGEX) ? (
				<div className="flex flex-col items-center justify-center rounded-3xl px-4 py-6 shadow-small">
					<PredictiveSearchGrid
						searchTerm={searchTerm}
						genderFilter={genderFilter}
						closeOverlay={closeOverlay}
						updateHits={updateHits}
						updateProductDataLength={updateProductDataLength}
						onGenderFilterChange={handleGenderFilterChange}
						updateBrands={setBrands}
						updateProductNames={setProductNames}
						updatePageSearchUrl={updatePageSearchUrl} // Pass the function to PredictiveSearchGrid
					/>
				</div>
			) : (
				<div className="flex flex-col flex-wrap gap-6 overflow-y-auto rounded-3xl px-4 py-6 shadow-small md:grid md:h-auto md:grid-cols-3">
					<div
						className={`flex flex-col gap-4 md:col-span-1 ${
							productDataLength === 0 &&
							searchTerm.length > 1 &&
							pageSearchUrl?.estimated_total_count === 0
								? 'hidden'
								: ''
						}`}
					>
						<SearchGenderFilter
							activeFilter={genderFilter}
							onFilterChange={handleGenderFilterChange}
						/>
						<GridSuggestions
							searchTerm={searchTerm}
							closeOverlay={closeOverlay}
							onSuggestionClick={handleSuggestionClick}
							brands={brands}
							productNames={productNames}
						/>
					</div>
					<div
						className={`md:col-span-2 ${
							productDataLength === 0 &&
							searchTerm.length > 1 &&
							pageSearchUrl?.estimated_total_count === 0
								? 'md:col-span-3'
								: ''
						}`}
					>
						<PredictiveSearchGrid
							searchTerm={searchTerm}
							genderFilter={genderFilter}
							closeOverlay={closeOverlay}
							updateHits={updateHits}
							updateProductDataLength={updateProductDataLength}
							onGenderFilterChange={handleGenderFilterChange}
							updateBrands={setBrands}
							updateProductNames={setProductNames}
							updatePageSearchUrl={updatePageSearchUrl} // Pass the function to PredictiveSearchGrid
						/>
					</div>
				</div>
			)}
		</>
	)
}

export default SearchResults
