import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import { Icon } from '#app/components/ui/icon'
import { type DiscountStoryblok } from '#types/component-types-sb'

const DiscountLocationAndAudience = ({ discount }: DiscountStoryblok) => {
	const { t } = useTranslation('navbar')

	return (
		<div className="flex w-full items-center justify-between">
			<PlentyBody className="text-light-grey-90">
				{discount.whoIsTheDiscountFor
					?.map((audience: string) => t(`${audience.toLowerCase()}_header`))
					.join(', ')}
			</PlentyBody>
			<div className="flex items-center gap-1">
				<Icon className="text-light-grey-90" size="sm" name="location-pin" />
				<PlentyBody className="text-light-grey-90">{discount.city}</PlentyBody>
			</div>
		</div>
	)
}

export default DiscountLocationAndAudience
