import { useState } from 'react'
import { Icon } from '#app/components/ui/icon'
import MobileNavClerkSearch from '#app/features/clerk-search-mobile-nav/index'
import { cn } from '#app/utils/misc'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'
import ScrollableContent from '../scrollable-content'
import { SlideContent, SlideTrigger } from '../slides'
import SearchNavigation from './search-navigation'

interface MobileSearchProps {
	selectedCategory: string | null
	onCategoryClick: (title: string) => void
	navigationData: NavigationCategoriesStoryblok[]
}

const MobileSearch = ({
	selectedCategory,
	navigationData,
	onCategoryClick,
}: MobileSearchProps) => {
	const [searchOpen, setSearchOpen] = useState(false)
	const handleCloseOverlay = () => {
		setSearchOpen(false)
	}
	const handleCategoryClick = (category: string) => {
		onCategoryClick(category)
		// Ensure that searchOpen remains true after selecting a category
		setSearchOpen(true)
	}
	return (
		<div>
			<SlideTrigger
				slideKey="mobile-search"
				onClick={() => {
					setSearchOpen(true)
				}}
				className={cn(searchOpen && 'hidden')}
			>
				<div className="absolute bottom-[2%] right-6 flex h-[40px] w-[58px] rounded-full border border-black-90 bg-mint-green-80p bg-opacity-80 px-4 py-3">
					<Icon name="magnifying-glass" className="h-6 w-6" />
				</div>
			</SlideTrigger>
			<SlideContent slideKey="mobile-search">
				<ScrollableContent className="h-full space-y-3">
					<SearchNavigation
						selectedCategory={selectedCategory}
						navigationData={navigationData}
						onCategoryClick={handleCategoryClick}
					/>
					<MobileNavClerkSearch
						closeOverlay={handleCloseOverlay}
						selectedCategory={selectedCategory}
					/>{' '}
				</ScrollableContent>
			</SlideContent>
		</div>
	)
}

export default MobileSearch
